<template>
  <main class="StudySearch">
    <div style="text-align: left;"><Head title="学习查询"></Head></div>
    <!-- <el-form :model="numberValidateForm" ref="numberValidateForm"  class="demo-ruleForm">
    <el-form-item
      label="身份证号码或手机号码:"
      prop="Num"
      :rules="[
        { required: true, message: '身份证号码或手机号码不能为空'},
        { type: 'number', message: '输入值必须为数字值'}
      ]"
    >
        <el-input v-model.number="numberValidateForm.Num" autocomplete="off"></el-input>
  </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('numberValidateForm')">查询</el-button>
        <el-button type="primary" @click="resetForm('numberValidateForm')">重置</el-button>
      </el-form-item>
    </el-form> -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="学习进度查询" name="first">
        <el-form
            :model="numberValidateForm"
            ref="numberValidateForm"
            label-width="80px"
            :inline="true"
            class="demo-ruleForm"
        >
          <el-form-item
              label="课程名称:"
              prop="courseName"
          >
            <el-select
                v-model="numberValidateForm.courseId"
                placeholder="请选择课程名称"
                clearable
                style="width: 200px">
              <el-option
                  v-for="item in enterpriseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
            </el-select
            >
          </el-form-item>
          <el-form-item
              label="手机号码:"
              prop="phone"
          >
            <el-input
                placeholder="请输入手机号码查询"
                v-model.number="numberValidateForm.phone"
                style="width: 200px"
                autocomplete="off"
            ></el-input>
            <el-button type="primary" @click="submitForm('numberValidateForm')"
            >查询</el-button>
          </el-form-item>
        </el-form>
          <el-table
            :data="tableData"
            style="width:100%"
            border
            :header-cell-style="{ background: '#f5f5f5' }"
          >
          <el-table-column type="index" width="50px" label="序号" />
            <el-table-column prop="name" label="姓名" >
            </el-table-column>
            <el-table-column prop="idCard" label="身份证号">
            </el-table-column>
            <el-table-column prop="phone" label="手机号">
            </el-table-column>
            <el-table-column prop="courseName" label="课程名">
            </el-table-column>
<!--            <el-table-column prop="CourseType" label="课程类型" >-->
<!--            </el-table-column>-->
            <el-table-column prop="startTime" label="开始学习时间" width="105px">
            </el-table-column>
            <el-table-column prop="progress" label="学习进度" >
              <template slot-scope="scope">
                <span>{{scope.row.progress}}%</span>
              </template>
            </el-table-column>
<!--            <el-table-column prop="OrderStatus" label="操作" >-->
<!--              <template slot-scope="scope">-->
<!--                <el-button-->
<!--                  type="primary"-->
<!--                  size="mini"-->
<!--                  @click="viewOrder(scope.row.id)"-->
<!--                  >订单详情</el-button-->
<!--                >-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
          <div class="pagination">
          <Pagination
            :total="total"
            :page.sync="search.pageNum"
            :limit.sync="search.pageSize"
          />
          </div>
      </el-tab-pane>
      <el-tab-pane label="证书查询" name="second">
        <el-form
            :model="numberValidateForm"
            ref="numberValidateForm"
            label-width="80px"
            :inline="true"
            class="demo-ruleForm"
        >
          <el-form-item
              label="课程名称:"
              prop="courseName"
          >
            <el-select
                v-model="numberValidateForm.courseId"
                placeholder="请选择课程名称"
                clearable
                style="width: 200px">
              <el-option
                  v-for="item in enterpriseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
            </el-select
            >
          </el-form-item>
          <el-form-item
              label="手机号码:"
              prop="phone"
          >
            <el-input
                placeholder="请输入手机号码查询"
                v-model.number="numberValidateForm.phone"
                style="width: 200px"
                autocomplete="off"
            ></el-input>
            <el-button type="primary" @click="submitForm('numberValidateForm')"
            >查询</el-button>
          </el-form-item>
        </el-form>
          <el-table
            :data="tableData2"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column type="index" width="50" label="序号" />
            <el-table-column prop="name" label="姓名" >
            </el-table-column>
            <el-table-column prop="idCard" label="身份证号">
            </el-table-column>
            <el-table-column prop="phone" label="手机号">
            </el-table-column>
            <el-table-column prop="certName" label="证书名称" >
            </el-table-column>
            <el-table-column prop="certCode" label="证书编号">
            </el-table-column>
<!--            <el-table-column prop="CertificateType" label="证书类型">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="IssuingAuthority" label="发证机构" >-->
<!--            </el-table-column>-->
            <el-table-column prop="publishTime" label="发证时间">
            </el-table-column>
<!--            <el-table-column prop="TermOfValidity" label="有效期">-->
<!--            </el-table-column>-->
            <el-table-column prop="ShowCertificate" label="操作" >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="ShowCertificate(scope.row.certUrl)"
                  >展示证书</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
          <Pagination
            :total="total"
            :page.sync="search.pageNum"
            :limit.sync="search.pageSize"
          />
          </div>
      </el-tab-pane>
<!--      <el-tab-pane label="成绩查询" name="third">-->
<!--        <el-form-->
<!--            :model="numberValidateForm"-->
<!--            ref="numberValidateForm"-->
<!--            label-width="80px"-->
<!--            :inline="true"-->
<!--            class="demo-ruleForm"-->
<!--        >-->
<!--          <el-form-item-->
<!--              label="课程名称:"-->
<!--              prop="courseName"-->
<!--          >-->
<!--            <el-select-->
<!--                v-model="numberValidateForm.courseId"-->
<!--                placeholder="请选择课程名称"-->
<!--                style="width: 200px">-->
<!--              <el-option-->
<!--                  v-for="item in enterpriseOptions"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"></el-option>-->
<!--            </el-select-->
<!--            >-->
<!--          </el-form-item>-->
<!--          <el-form-item-->
<!--              label="手机号码:"-->
<!--              prop="phone"-->
<!--          >-->
<!--            <el-input-->
<!--                placeholder="请输入手机号码查询"-->
<!--                v-model.number="numberValidateForm.phone"-->
<!--                style="width: 200px"-->
<!--                autocomplete="off"-->
<!--            ></el-input>-->
<!--            <el-button type="primary" @click="submitForm('numberValidateForm')"-->
<!--            >查询</el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--          <el-table-->
<!--            :data="tableData3"-->
<!--            style="width: 100%"-->
<!--            border-->
<!--            :header-cell-style="{ background: '#f5f5f5' }"-->
<!--          >-->
<!--            <el-table-column type="index" width="50" label="序号" />-->
<!--            <el-table-column prop="name" label="姓名" >-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="idCard" label="身份证号">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="phone" label="手机号">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="examName" label="考试名称" >-->
<!--            </el-table-column>-->
<!--&lt;!&ndash;            <el-table-column prop="CourseType" label="课程类型">&ndash;&gt;-->
<!--&lt;!&ndash;            </el-table-column>&ndash;&gt;-->
<!--            <el-table-column prop="startTime" label="考试时间">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="score" label="考试成绩">-->
<!--            </el-table-column> -->
<!--          </el-table>-->
<!--          <div class="pagination">-->
<!--          <Pagination-->
<!--            :total="total"-->
<!--            :page.sync="search.pageNum"-->
<!--            :limit.sync="search.pageSize"  -->
<!--          />-->
<!--          </div>-->
<!--      </el-tab-pane>-->
    </el-tabs>
    
  </main>
  
</template>

<script>
import Pagination from '@/components/Pagination'
import {getEnterpriseStudyProgress,getEnterpriseExamList,getEnterpriseCertificate} from '@/api/myCenter'
import {getEnterpriseOptions} from '@/api/myCenter'
import Head from './Head'
export default {
  name: "StudySearch",
  components:{
    Pagination,
    Head
  },
  data() {
    return {
      activeName: 'first',
      numberValidateForm: {
        phone: null,
        courseId: null
      },
      enterpriseOptions:[],
      search: {
        pageNum: 1,
        pageSize: 10,
      },
      total:0,//分页
      tableData: [
      ],
      tableData2: [
      ],
      tableData3: [
      ],
    };
  },
  created() {
    this.getCourseOptions();
    this.submitForm()
  },
  methods: {
    submitForm() {
      this.search.courseId = this.numberValidateForm.courseId
      this.search.phone = this.numberValidateForm.phone
      if(this.activeName === 'first'){
        getEnterpriseStudyProgress(this.search).then(res=>{
          console.log("res",res)
          this.tableData = res.data.data.rows
          this.total = res.data.data.total
        })
      }else if(this.activeName === 'second'){
        getEnterpriseCertificate(this.search).then(res=>{
          console.log("res",res)
          this.tableData2 = res.data.data.rows
          this.total = res.data.data.total
        })
      } else if(this.activeName === 'third'){
        getEnterpriseExamList(this.search).then(res=>{
          console.log("res",res)
          this.tableData3 = res.data.data.rows
          this.total = res.data.data.total
        })
      }
    },
    getCourseOptions(){
      getEnterpriseOptions().then(res=>{
        console.log("res",res)
        this.enterpriseOptions = res.data.data
      })
    },
    handleClick() {
      this.submitForm()
    },
    resetForm(formValue) {
      this.$refs[formValue].resetFields();
    },
    viewOrder(orderId) {
      this.$router.push({
        path: 'orderDetail',
        query:{
          id: orderId
        }
      })
    },
    ShowCertificate(url){
      window.open(url)
    },
    // 分页器部分
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val){
        console.log(`当前页 ${val}`)
      }
  },
};
</script>

<style lang="scss" scoped>
.StudySearch {
  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .el-input {
    width: 50%;
    float: left;
    height: 40px;
  }
  .el-form-item__error::after {
    margin-left: 200px;
  }
  .el-button--medium {
    float: left;
    margin-left: 10px;
  }
  .pagination{
    margin-top: 30px;
  }
}
</style>